<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pt-2" />
        <v-data-table
          :headers="headers"
          :items="itemsCobradores"
          class="elevation-1"
          :search="search"
          item-key="venId"
          show-expand
          :expanded.sync="expanded"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="handleExpansion(item, isExpanded)"
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  >{{ "mdi-chevron-down" }}</v-icon
                >
              </template>
              <span>Ver otros datos</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Dirección:</strong>
              {{ item.direccion }}
              <strong style="padding-left: 80px">Observaciones:</strong>
              {{ item.observaciones }}
            </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }" >
            <v-tooltip left v-if="canEdit && item.venId != 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar cobrador</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete && item.venId != 0">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteCobrador(item.venId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar cobrador</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-col cols="12" class="px-0">
          <v-btn
            color="primary"
            class="to-right"
            outlined
            :disabled="itemsCobradores.length == 0"
            @click="exportarConsulta()"
          >
            Exportar detalle
          </v-btn>
        </v-col>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="55%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditCobradores
        :cobrador="cobradoresParams"
        @closeAndReload="closeAndReload"
      ></EditCobradores>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditCobradores from "@/components/modules/cuotas/configuracion/EditCobradores.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  components: { PageHeader, DeleteDialog, EditCobradores, GoBackBtn, Ayuda },
  name: "Cobradores",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    expanded: [],
    showDeleteModal: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.COBRADORES,
    idToDelete: null,
    routeToGo: "DevengamientosCobranzas",
    title: enums.titles.CONSULTA_COBRADORES,
    titleDelete: "¿Eliminar cobrador?",
    rules: rules,
    itemsCobradores: [],
    cobradoresParams: {},
    headers: [
      {
        text: "ID",
        align: "start",
        value: "venId",
        sortable: false
      },
      {
        text: "Nombre",
        value: "venNombre",
        sortable: false
      },
      {
        text: "IVA",
        value: "tipoIva.value",
        sortable: false
      },
      {
        text: "CUIT",
        value: "cuit",
        sortable: false
      },
      {
        text: "Teléfono",
        value: "telefono",
        sortable: false
      },
      {
        text: "Email",
        value: "email",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end"
      }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadCobradores();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getCobradores: "configuracion/getCobradores",
      deleteCobradores: "configuracion/deleteCobradores",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_COBRADOR:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_COBRADOR:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_COBRADOR:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadCobradores() {
      const data = await this.getCobradores();
      this.itemsCobradores = data;
    },
    deleteCobrador(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteCobradores({
        venId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadCobradores();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.cobradoresParams = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.cobradoresParams = {};
      this.loadCobradores();
    },
    exportarConsulta() {
      let result = [];
      this.itemsCobradores?.forEach(x =>
        result.push({
          ["ID"]: x.venId,
          ["Nombre"]: x.venNombre,
          ["CUIT"]: x.cuit,
          ["IVA"]: x.tipoIva != null ? x.tipoIva.value : "",
          ["Dirección"]: x.direccion,
          ["Teléfono"]: x.telefono,
          ["Email"]: x.email,
          ["Observaciones"]: x.observaciones
        })
      );
      let formato = {
        filas: result,
        hoja: "Cobradores"
      };
      helpersExcel.excelExport(formato, "Consulta de cobradores");
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
